import React, { Dispatch, FC, SetStateAction } from "react";
import { Charity, User } from "./RequestForm";

interface IProps {
  user?: User;
  charity?: Charity;
  close: Dispatch<SetStateAction<boolean>>;
  success: CallableFunction;
  failure: CallableFunction;
}

const ValidationSuccess: FC<IProps> = ({
  user,
  charity,
  close,
  success,
  failure,
}) => {
  return (
    <div
      className={
        "flex flex-col items-center my-10 bg-blue-100 text-center gap-10 p-10 z-10"
      }
    >
      <h2 className={"flex text-5xl text-blue-900 flex-nowrap"}>
        {user ? "Is This You?" : "Is This Your Organization?"}
      </h2>
      {user && (
        <>
          <p
            className={"text-blue-900"}
          >{`${user.firstName} ${user.lastName}`}</p>
          <p className={"text-blue-900"}>{`Email: ${user.email}`}</p>
        </>
      )}
      {charity && (
        <>
          <p className={"text-blue-900"}>{charity.name}</p>
          <p className={"text-blue-900"}>{`EIN: ${charity.ein}`}</p>
          <p className={"text-blue-900"}>{charity.address.street}</p>
          <p className={"text-blue-900"}>{charity.address.street2}</p>
          <p
            className={"text-blue-900"}
          >{`${charity.address.city}, ${charity.address.state}`}</p>
          <p className={"text-blue-900"}>{charity.address.zip}</p>
          <p className={"text-blue-900"}>{charity.address.phone}</p>
        </>
      )}

      <div className={"flex gap-3"}>
        <button
          className={
            "flex justify-center align-middle w-1/2 rounded-lg bg-amber-500 text-slate-900 text-lg py-3.5 px-5 cursor-pointer hover:bg-amber-400"
          }
          onClick={() => {
            failure();
            close(false);
          }}
        >
          No
        </button>
        <button
          className={
            "flex justify-center align-middle w-1/2 rounded-lg bg-green-700 text-white text-lg py-3.5 px-5 cursor-pointer hover:bg-green-600"
          }
          onClick={() => {
            if (user) {
              success();
            }
            close(false);
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default ValidationSuccess;
