import axios from "../utils/axios";

export const validateUser = async (email: string) => {
  const {
    data: { response, error },
  } = await axios
    .post(
      `/.netlify/functions/validate-user`,
      { email },
      { headers: { "Content-Type": "application/json" } }
    )
    .catch((error) => {
      return { data: { error: error.response } };
    });
  return { response, error };
};
