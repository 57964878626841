import axios from "../utils/axios";

export const validateCharity = async (charityID: string) => {
  const {
    data: { response, error },
  } = await axios.post(
    `/.netlify/functions/validate-charity`,
    { charityID },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return { error, response };
};
