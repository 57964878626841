import axios from "../utils/axios";
import { FormSubmission } from "../components/RequestForm";

export const submitFormToGivinga = async (data: FormSubmission) => {
  const {
    data: { response, error },
  } = await axios
    .post(`/.netlify/functions/submit-to-givinga`, data, {
      headers: {
        accept: "application/json",
      },
    })
    .catch((error) => {
      return { data: { error: error.response } };
    });
  return { error, response };
};
