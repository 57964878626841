import React, { Dispatch, FC, SetStateAction } from "react";

const userMessage = (
  <p>
    We were unable to find a user in our system with the given email address.
    Please try your work email address if you have not already, or contact your
    administrator.
  </p>
);

const charityMessage = (
  <p>
    We were unable to locate a verified charity match for the provided EIN. If
    you feel this is an error, please contact{" "}
    <a
      className={
        "underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
      }
      href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
    >
      {process.env.REACT_APP_SUPPORT_EMAIL}
    </a>
  </p>
);

interface IProps {
  close: Dispatch<SetStateAction<boolean>>;
  type: string;
}

const ValidationFailure: FC<IProps> = ({ type, close }) => {
  return (
    <div
      className={
        "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-gray-300 flex w-1/2 flex-col text-center gap-10 p-10 z-10"
      }
    >
      <h1 className={"text-5xl"}>
        {type === "user" ? "No User Found" : "No Matches Found"}
      </h1>
      {type === "user" ? userMessage : charityMessage}

      <button
        className={
          "self-center rounded-lg bg-amber-500 text-slate-800 px-5 sm:text-lg py-2.5 sm:px-5 cursor-pointer hover:bg-amber-500"
        }
        onClick={() => close(false)}
      >
        Done
      </button>
    </div>
  );
};

export default ValidationFailure;
